<template>
</template>
<script>
import script from '../script'
export default {
  name: 'home',
  mounted () {
    console.log('Home')
    if (localStorage.getItem('isLogin')) {
      script.getUser().then((res) => {
        this.$router.push('/dashboard')
      }).catch(function (err) {
        localStorage.clear()
        window.location.reload()
        console.log('Cant get user, Home ' + err)
        this.$router.push('/login')
      })
    } else {
      localStorage.clear()
      window.location.reload()
      this.$router.push('/login')
    }
  }
}
</script>